<template>
    <div class="product-images">
        <div class="img-group col s12 m6 l6 xl5">
            <div class="main-image">
                <img
                    class="responsive-img mainImg"
                    :src="mainImg"
                    alt=""
                    @click.prevent="openFullSizeImage(mainImg, indexImg)"
                />
            </div>

            <div class="preview-line">
                <img
                    v-for="(img, index) in allImages"
                    :key="img"
                    class="responsive-img otherImg"
                    :class="{ active: current === index }"
                    @click.prevent="slider(img, index)"
                    :src="img"
                    alt=""
                />
            </div>
        </div>

        <div
            class="fullscreen-slider-parent"
            :class="{ openFullscreen: openFullscreen }"
        >
            <div class="fullscreen-slider center">
                <div class="carousel carousel-slider" ref="carousel">
                    <a v-for="img in allImages" class="carousel-item">
                        <img :src="img" />
                    </a>
                </div>
            </div>

            <button
                class="btn-close btn-flat"
                @click.prevent="closeFullSizeImage"
            >
                <i class="material-icons">close</i>
            </button>

            <button
                class="btn-previous btn-flat"
                @click.prevent="carousel.prev()"
            >
                <i class="material-icons">keyboard_arrow_left</i>
            </button>

            <button
                class="btn-next btn-flat center"
                @click.prevent="carousel.next()"
            >
                <i class="material-icons">keyboard_arrow_right</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        openFullscreen: false,
        carousel: null,
        current: 0,
        indexImg: 0,
        mainImg: null,
        activeImg: null,
        allImages: [],
    }),

    async mounted() {
        try {
            this.allImages = this.product.otherImages;
            this.allImages.unshift(this.product.mainImageSrc);
            this.mainImg = this.product.mainImageSrc;
            this.current = 0;
        } catch (e) {
            console.log(e);
        }

        setTimeout(() => {
            this.carousel = M.Carousel.init(this.$refs.carousel, {
                indicators: true,
            });
        }, 0);
    },

    methods: {
        slider(img, index) {
            this.mainImg = img;
            this.current = index;
            this.indexImg = index;
        },

        closeFullSizeImage() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.openFullscreen = false;
        },

        openFullSizeImage() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.openFullscreen = true;
        },
    },
};
</script>

<style scoped lang="less">
@mobileMenuBreakpoint: 650px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;

.img-group {
    padding-left: 3%;
}
.main-image {
    margin: 50px 20px 0 30px;
    cursor: pointer;
    .mainImg {
        max-height: 40rem;
        height: 60vh;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
        object-position: top;
    }
}
.preview-line {
    margin: 20px 0 0 30px;
    float: left;
    display: inline-flex;

    .otherImg {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        height: 5rem;
        width: 5rem;
        margin-right: 25px;
        transition: all 0.2s linear;
        object-fit: cover;
    }
    .active {
        opacity: 0.7;
        transition: all 0.2s linear;
        transform: scale(1.2);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    }
}

.fullscreen-slider-parent {
    z-index: 990;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(0);
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(255, 255, 255, 1);
    height: 100vh;
    width: 100vw;
    .fullscreen-slider {
        height: 98vh;

        .carousel {
            height: 98vh;
            padding: 5rem;
            .carousel-item {
                img {
                    width: 80vw;
                    height: 90vh;
                    object-fit: scale-down;
                }
            }
        }
    }

    &.openFullscreen {
        opacity: 1;
        transform: scale(1);
        transition: all 0.2s;
    }

    .btn-close {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        width: 6rem;
        height: 6rem;
        i {
            height: 6rem;
            line-height: 6rem;
            font-size: 4rem;
            color: grey;
        }
    }

    .btn-next,
    .btn-previous {
        background-color: rgba(255, 255, 255, 0.5);
        width: 6rem;
        height: 65vh;
        position: absolute;
        bottom: 20vh;
        i {
            float: inherit;
            z-index: 992;
            font-size: 4rem;
            color: grey;
        }
    }

    .btn-next {
        right: 0;
    }

    .btn-previous {
        left: 0;
    }
}
</style>
