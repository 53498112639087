<template>
	<div
		class="product-images col s12 m12 l5 xl5"
		:class="{ openFullscreen: openFullscreen }"
	>
		<div class="swiper-container">
			<Swiper :options="swiperOption" ref="mySwiper">
				<!-- slides -->
				<SwiperSlide
					class="swiper-slide"
					v-for="img in allImages"
					:key="img"
				>
					<img
						class="responsive-img img-slide-item"
						:src="img"
						@click.prevent="openFullSizeImage(img)"
						alt=""
					/>
				</SwiperSlide>

				<div class="swiper-container gallery-thumbs">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<img
								v-for="(img, index) in allImages"
								:key="img"
								class="responsive-img otherImg"
								:src="img"
								alt=""
							/>
						</div>
					</div>
				</div>

				<!-- Optional controls -->
				<div class="swiper-pagination" slot="pagination"></div>
			</Swiper>
		</div>
		<div
			class="fullscreen-slider-parent"
			:class="{ openFullscreen: openFullscreen }"
		>
			<div class="fullscreen-image">
				<div v-for="img in allImages" class="gallery">
					<input type="checkbox" id="zoomCheck" />
					<label for="zoomCheck">
						<img class="responsive-img" :src="img" alt="" />
					</label>
				</div>
			</div>

			<button
				class="btn-close btn-flat"
				@click.prevent="closeFullSizeImage"
			>
				<i class="material-icons">close</i>
			</button>
		</div>
	</div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	data: () => ({
		openFullscreen: false,
		carousel: null,
		current: 0,
		indexImg: 0,
		mainImg: null,
		activeImg: null,
		swiperOption: {
			spaceBetween: 60,
			mousewheel: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet(index, className) {
					return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
				},
			},
		},
	}),

	computed: {
		allImages() {
			const { otherImages = [], mainImageSrc } = this.product ?? {};
			return [mainImageSrc].concat(otherImages);
		},
		swiper() {
			return this.$refs.mySwiper.swiper;
		},
	},
	methods: {
		closeFullSizeImage() {
			window.scroll({
				top: 0,
				left: 0,
			});
			this.openFullscreen = false;
		},

		openFullSizeImage(img) {
			this.mainImg = img;
			this.openFullscreen = true;

			window.scroll({
				top: 0,
				left: 0,
			});
		},
	},
};
</script>

<style scoped lang="less">
@mobileMenuBreakpoint: 650px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;

.product-images {
	.main-image {
		.mainImg {
			cursor: pointer;
			width: 100%;
			border-radius: 5px;
			object-fit: cover;
			object-position: top;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
		}
	}
}

@media screen and (max-width: 900px) {
	.swiper-container {
		height: 29rem;
		.swiper-wrapper {
			height: 29rem;
			.swiper-slide {
				height: 25rem;
				img {
					height: 25rem;
					object-fit: contain;
				}
			}
		}
	}
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
	.swiper-container {
		height: 29rem;
		.swiper-wrapper {
			height: 29rem;
			.swiper-slide {
				height: 25rem;
				img {
					height: 25rem;
					object-fit: contain;
				}
			}
		}
	}
}

input[type='checkbox'] {
	display: none;
}

.fullscreen-slider-parent {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	z-index: 999;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	opacity: 0;
	transition: all 0.2s;
	background-color: rgba(255, 255, 255, 0.99);
	height: 100vh;
	width: 100vw;
	transform: translateX(-100vw);

	.btn-close {
		z-index: 950;
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 5rem;
		height: 5rem;
		i {
			font-size: 3.5rem;
			line-height: 10vh;
			color: grey;
		}
	}

	.fullscreen-image {
		height: 100vh;
		img {
			object-fit: contain;
			height: 85vh;
			transition: transform 0.25s ease;
			cursor: zoom-in;
		}
	}

	&.openFullscreen {
		position: fixed;
		overflow: hidden;
		background-color: rgba(255, 255, 255, 0.99);
		height: 100vh;
		width: 100vw;

		opacity: 1;
		transform: translateX(0vw);

		display: block;
		transition: all 0.5s;

		.fullscreen-image {
			height: 100vh;
			overflow-x: scroll;
			overflow-y: scroll;

			img {
				height: 100vh;

				/*cursor: zoom-in;*/
			}

			/*input[type=checkbox]:checked ~ label > img {*/

			/*    padding: 10vh 0vh 0vh 20vh;*/
			/*    margin: 0;*/
			/*    !*padding: 50% 0 0 35%;*!*/
			/*    object-fit: contain;*/

			/*    transform: scale(2);*/
			/*    background-position: center;*/
			/*    cursor: zoom-out;*/
			/*}*/
		}
	}
}
</style>
