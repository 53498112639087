<template>
	<section class="center main-page ">
		<Loader v-if="loading" />

		<div v-else class="product-page row ">
			<div class="sliders-div">
				<mobile-slider v-if="isMobile" :product="product" />
				<slider v-if="!isMobile" :product="product" />
			</div>

			<div
				class="product-details col s12 m12 l5 xl4  offset-l1 offset-xl1 "
			>
				<div class=" product-info col s11 m12 l12 xl12 ">
					<span class="product-title">{{ product.title }}</span>

					<div class="availible-block">
						<span class="product-price"
							>{{ product.price | currency }}
						</span>

						<div class="product-availability row">
							<i class="material-icons">check_circle</i>
							<span class="availability-span"> В наличии</span>
						</div>
					</div>

					<div class="product-buy grey lighten-4 row">
						<button
							class="btn btn-block reduce btn-flat  waves-effect  grey lighten-4 col s3 m3 l3 xl3"
							@click="decCount(product)"
							:class="{ disabled: amount <= 1 }"
						>
							<i class="material-icons">remove</i>
						</button>

						<div class="block grey lighten-4 col s2 m2 l2 xl2">
							<p class="amount">{{ amount }}</p>
						</div>

						<button
							class="btn btn-block add  btn-flat waves-effect grey lighten-4 col s3 m3 l3 xl3"
							@click="incCount(product)"
						>
							<i class="material-icons">add</i>
						</button>

						<button
							v-if="!inCart"
							class="btn btn-block btn-large btn-flat  waves-effect buy col s8 m8 l8 xl8"
							@click.prevent="addProductToCart"
						>
							<p>Купить</p>
						</button>

						<button
							v-else
							class="btn btn-block btn-large btn-flat  waves-effect done col s8 m8 l8 xl8"
							@click.prevent="addProductToCart"
						>
							<p>В корзине</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import slider from '../../components/app/Slider';
import mobileSlider from '../../components/app/MobileSlider';

export default {
	data: () => ({
		name: 'product-page',
		product: {},
		products: [],
		categories: [],
		loading: true,
		matchingProducts: [],
		amount: 1,
		cartAmount: 0,
		carousel: null,
		cart: null,
		inCart: false,
		cartItem: {},
	}),
	components: {
		mobileSlider,
		slider,
	},

	methods: {
		incCount(product) {
			this.amount++;
		},

		decCount(product) {
			this.amount--;
		},

		addProductToCart() {
			this.cartItem = {
				productId: this.$router.currentRoute.params.id,
				productImage: this.product.mainImageSrc,
				productTitle: this.product.title,
				amount: this.amount,
				price: this.product.price,
				sum: this.amount * this.product.price,
			};
			if (this.amount > 1) {
				this.$changeQuantity();
			} else {
				this.$addToCart();
			}

			this.inCart = true;
			this.$store.commit('addProductToCart', this.cartItem);
		},
	},

	computed: {
		isMobile() {
			return window.screen.width <= 800;
		},
	},
	async mounted() {
		try {
			this.products = this.$store.getters.getProducts;
			this.product = await this.$store.dispatch(
				'fetchSingleProductById',
				this.$route.params.id,
			);

			const cart = this.$store.getters.getCart;
			const cartItem = cart.find(
				(val) => val.productId === this.$route.params.id,
			);

			if (cartItem) {
				this.inCart = true;
				this.amount = cartItem.amount;
			} else {
				this.amount = 1;
			}

			this.loading = false;
		} catch (e) {
			console.log(e);
		}
	},
};
</script>

<style scoped lang="less">
@mobileMenuBreakpoint: 650px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;

.product-page {
	margin-top: 1rem;
	height: 100%;
	width: 100%;
	margin-bottom: 40vh;
}
@media screen and (max-width: 450px) {
	.product-details {
		position: absolute;

		.product-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			.product-title {
				position: absolute;
				left: 10%;
				top: 30rem;
				font-weight: 600;
				font-size: 2.2rem !important;
				text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
				color: #404040;
			}

			.availible-block {
				position: absolute;
				top: 32rem;
				max-width: 98vw !important;
				width: 98%;

				display: flex;
				flex-direction: row;
				justify-content: space-evenly;

				.product-availability {
					position: relative;
					top: 30px;
					width: 120px;
				}
				.product-price {
					position: relative;
					top: 20px;
					width: 110px;
				}
			}
		}

		.product-buy {
			max-width: 90vw !important;
			width: 90%;
			left: 5%;
			bottom: 0;
			position: fixed;
			z-index: 700;

			p {
				font-size: 1rem !important;
			}
		}
	}
}

@media screen and (min-width: 450px) and (max-width: 580px) {
	.product-details {
		position: absolute;

		.product-info {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			.product-title {
				position: absolute;
				width: 80%;
				left: 12%;
				top: 29rem;
				font-weight: 600;
				font-size: 2.2rem !important;
				text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
				color: #404040;
			}

			.availible-block {
				position: absolute;
				top: 32rem;
				width: 90%;
				left: 7%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;

				.product-availability {
					position: relative;
					top: 30px;
					width: 180px;
				}
				.product-price {
					position: relative;
					top: 20px;
					width: 180px;
				}
			}
		}

		.product-buy {
			max-width: 80vw !important;
			width: 80%;
			padding: 15%;
			left: 10%;
			bottom: 0;
			position: fixed;
			z-index: 700;
		}
	}
}

@media screen and (min-width: 580px) and (max-width: 800px) {
	.sliders-div {
		position: relative;
		margin-top: 2rem;
	}

	.product-details {
		position: absolute;

		.product-info {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			.product-title {
				position: absolute;
				top: 30rem;
				font-weight: 600;
				font-size: 2.2rem !important;
				text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
				color: #404040;
			}

			.availible-block {
				position: fixed;
				bottom: 2rem;
				width: 90%;
				right: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				.product-availability {
					position: relative;
					top: 20px;
					width: 180px;
				}
				.product-price {
					position: relative;
					top: 20px;
					width: 180px;
				}
			}
		}

		.product-buy {
			width: 90%;
			bottom: 0;
			position: fixed;
			right: 5%;
			z-index: 700;
		}
	}
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
	.product-details {
		position: absolute;
		.product-info {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			.product-title {
				position: absolute;
				width: 36%;
				right: 5%;
				top: 5rem;
				font-weight: 600;
				font-size: 2.2rem !important;
				text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
				color: #404040;
			}

			.availible-block {
				position: absolute;
				top: 20rem;
				width: 35%;
				right: 5%;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;

				.product-availability {
					position: relative;
					top: 30px;
					width: 180px;
				}
				.product-price {
					position: relative;
					top: 20px;
					width: 180px;
				}
			}
		}

		.product-buy {
			width: 25rem;
			right: 5%;
			bottom: 0;
			position: fixed;
			z-index: 700;
		}
	}
}

@media screen and (min-width: 1000px) {
	.product-title {
		margin-top: 2rem;
		display: block;
		font-weight: 600;
		font-size: 2.5rem;
		text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
		color: #404040;
	}
	.product-availability {
		position: relative;
	}
	.product-price {
		margin-top: 10rem !important;
	}
	.product-buy {
		margin-top: 2rem;
	}
}

.product-details {
	.product-info {
		padding: 2rem 0 0rem 1rem;
		display: flex;

		text-align: left;
		flex-direction: column;
		justify-content: flex-end;

		.product-title {
			display: block;

			font-weight: 600;
			font-size: 2.5rem;
			text-shadow: 1px 1px 2px rgba(203, 180, 196, 0.5);
			color: #404040;
		}

		.product-price {
			display: flex;

			margin-top: 1rem;
			text-align: left;
			letter-spacing: 1px;
			font-size: 2.3rem;
			font-weight: 700;
			font-style: italic;
			color: #fd5949;
			text-shadow: 1px 1px 2px rgba(92, 81, 80, 0.32),
				-1px -1px 1px rgba(255, 255, 255, 0.9);
		}

		.product-availability {
			margin-top: 14px;
			margin-left: 2px;
			display: block;
			height: 30px;

			i {
				top: -15px;
				position: absolute;
				color: #00f3a5;
				margin-top: 15px;
				text-align: left;
				display: inline-block;
			}

			.availability-span {
				width: 50%;
				padding-bottom: 15px;
				font-weight: bolder;
				color: #343434;
				font-size: 0.9rem;
				margin-left: 35px;
				text-align: left;
				text-transform: uppercase;
			}
		}

		.product-buy {
			max-width: 25rem;
			box-shadow: 6px 2px 46px rgba(130, 127, 126, 0.68),
				-6px -2px 16px rgba(255, 255, 255, 0.5);
			border-radius: 10px;
			margin: 2rem 0 2rem 1px;
			display: inline-flex;
			flex-direction: row;
			height: 5.9rem;
			justify-content: space-between;
			align-items: center;

			padding: 0 3px 0 5px;

			button {
				height: 94%;
				border-radius: 10px;
			}

			i {
				color: grey;
				margin-top: 6px;
				font-size: 1.8rem;
			}

			.block {
				p {
					line-height: 1rem;
					margin-left: 5px;
					font-size: 1.4rem;
					font-weight: 500;
				}
			}
			.add {
				height: 4.7rem;
				margin-right: 7px;
			}
			.reduce {
				height: 4.7rem;
			}

			.buy {
				display: block;
				background: linear-gradient(
					69.08deg,
					#00ed8f 2.63%,
					#00c459 132.25%
				);
				box-shadow: 1px 2px 5px #00d76c,
					-6px -6px 16px rgba(255, 255, 255, 0.1);
				right: 3px;
				border-radius: 7px;
				height: 5rem;

				p {
					display: block;
					letter-spacing: 5px;
					line-height: 2rem;
					color: white;
					font-weight: 600;
					font-size: 1.3rem;
				}
			}

			.done {
				background: linear-gradient(
					69.08deg,
					#00e085 2.63%,
					#bfd3b0 132.25%
				);

				right: 3px;
				border-radius: 7px;
				height: 5rem;

				p {
					letter-spacing: 3px;
					line-height: 2rem;
					color: white;
					font-weight: 600;
					font-size: 1.2rem;
				}
			}
		}
	}
}
</style>
